////////////////////////////////////////////////////////////
//  Program: Inspection Form.jsx                          //
//  Application:  Inspection  Form                        //
//  Option: List of Template                              //
//  Developer: kiran singh                                //
//  Date: 2022-04-08                                      //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Button, Table, Modal } from "react-bootstrap";
import InspectionService from "../../services/InspectionService";
// import Moment from "moment";
import { Link } from "react-router-dom";
import exportIcon from "./../../assets/images/export-icon.svg";
// import blacktick from "./../../assets/images/black-tick.svg";
import greentick from "./../../assets/images/green-tick.svg";
// import renderHTML from 'react-render-html';
import redtick from "./../../assets/images/red-tick.svg";
import bluetick from "./../../assets/images/blue-tick.svg";
import brownArrow from "./../../assets/images/brown-arrow.svg";
import moment from 'moment-timezone';
import DeleteImageModal from '../DeleteImageModal';
import ActionItemService from "../../services/ActionItemService";


export class InspectionForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      showBasicModal: false,
      slug: this.props.match.params.slug,
      submission: [],
      mro: [],
      answer: global.mroAnswer,
      isSubmit: false,
      loading: false,
      deleteInspModal:false,
      showModalImage: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.insPdfGenerator = this.insPdfGenerator.bind(this);
    this.opendeleteInspectionModal = this.opendeleteInspectionModal.bind(this);
    this.onCloseImagePop = this.onCloseImagePop.bind(this);
        
  }
  opendeleteInspectionModal(slug) {
    this.setState({ deleteInspModal: true });
  }
  closedeleteInspModal() {
    this.setState({ deleteInspModal: false });
  }
  async insPdfGenerator(queryString = "") {
    this.setState({ loading: true });
    var list = [];
    let res = await InspectionService.getInspectionPdfBySlug(this.state.slug);
    if (global.successStatus.includes(res.status)) {
      list = res.data.report["0"] ? res.data.report["0"] : [];
    }
    this.setState({ list: list });
    this.setState({ loading: false });
    window.open(this.state.list.filePath, "_blank");
  }
  closeModal() {
    this.setState({ showBasicModal: false });
  }
  async componentDidMount() {
    this.getInspection();
  }

  async getInspection() {
    this.setState({ loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await InspectionService.getInspectionBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data.data ? res.data.data : {};
        resData.inspection_no = resData.inspection_no
          ? resData.inspection_no
          : "";
        resData.zone_type = resData.zone_type
          ? resData.zone_type.zone_name
          : "";
        resData.checklist = resData.checklist
          ? resData.checklist.checklist_name
          : "";
        resData.property_name = resData.property
          ? resData.property.property_name
          : "";
        resData.technician = resData.inspector
          ? resData.inspector.full_name
          : "";
        resData.room = resData.room ? resData.room.room_name : "";
        resData.commercial = resData.commercial
          ? resData.commercial.commercials_zone_name
          : "";
        resData.zone = resData.zone ? resData.zone.equipment_zone_name : "";
        resData.submission = resData.submission ? resData.submission : "";
        resData.category = resData.category ? resData.category : "";
        resData.mro = resData.mro ? resData.mro : "";
        resData.status_id = resData.status_id == 1 ? true : false;
        resData.timezone = resData?.property ? resData?.property?.timezone?.timezone_name : "";
        this.setState(resData);
        this.setState({
          // time_out: Moment(resData.out_time).format("DD MMM, YY - hh:mm A"),
          time_out: moment(moment.utc(resData.out_time).toDate()).local().format("DD MMM, YY - hh:mm A"),
        });
        this.setState({
          // time_in: Moment(resData.in_time).format("DD MMM, YY - hh:mm A"),
          time_in: moment(moment.utc(resData.in_time).toDate()).local().format("DD MMM, YY - hh:mm A"),
        });
      }
      this.setState({ loading: false });
      var isAvailConst = 0;
      if (this.state.category) {
        this.state.category.map((cat, j) => {
          if (cat.tasks) {
            cat.tasks.map((tsk, i) => {
              if (tsk.is_mro_with_note == 1) {
                isAvailConst = 1;
              }
            });
          }
        });
      }
      this.setState({ isAvail: isAvailConst });
    }
  }
  // delete insp

  async deleteInsp(slug) {
    this.setState({ loading: true, isDelete: true });
    let res = await InspectionService.deleteInspection(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteInspModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(() => this.props.history.push("/inspections"), global.redirect_time);
    } else {
      this.closedeleteInspModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false, isDelete: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  // Delete MRO Image Start
  async deleteImage(slug) {
    this.setState({ loading: true, isSubmit: true });
    let res = await ActionItemService.deleteImage(slug);
    if (global.successStatus.includes(res.status)) {
      this.setState(prevState => ({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
        showModalImage: false,
        classRemove: false,
        mroImages: prevState.mroImages.filter(image => image.slug !== slug)  // Remove the deleted image
      }));
      this.getInspection();
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  // Delete MRO Image End
  mroRedirect() {
    window.open(`/mros/update/${this.state.mro_slug}` );
  }
  imageRedirect(path) {
    window.open(`${path}` );
  }
  modifyTaskName(taskName) {
    // Create a temporary element to manipulate the HTML string
    const tempElement = document.createElement('div');
    tempElement.innerHTML = taskName;

    // Find all links in the element
    const links = tempElement.querySelectorAll('a');
    links.forEach(link => {
      link.setAttribute('target', '_blank');
    });

    // Return the modified HTML
    return tempElement.innerHTML;
  }
  toggleModal(slug) {
    // this.setState({ showModalImage: !this.state.showModalImage, imageSlug:slug });
    this.setState({
      showModalImage: true,
      imageSlug: slug,
      classRemove: true,
    });
  }
  onCloseImagePop() {
    this.setState({ showModalImage: false, classRemove: false});
  }
  render() {
    const additionalClass = this.state.classRemove ? 'd-none' : '';
    return (
      <div>
        <main>
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="dashbaord-pageHeadertitle ">
                  <h3 className="flex-column">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h3>Inspections</h3>
                      <div className="btnNavigate d-flex justify-content-end">
                      {global.userPermissions.checkPermission("inspection-delete") && (
                                <Button
                                  type="button"
                                  onClick={() => {
                                    this.opendeleteInspectionModal(this.state.slug);
                                  }}
                                  className="gf-primary-btn-small-one  mg-l-7 mg-r-7 loader_btn delete-inspection-btn"
                                >
                                  Delete Inspection
                                </Button>)}
                        {global.userPermissions.checkPermission("inspection-list") && (
                        <Button
                          onClick={this.insPdfGenerator}
                          className="gf-primary-btn-small-one loader_btn pdf_gen"
                        >
                          <img src={exportIcon} alt="" />
                            {this.state.loading ? global.loader : "Export PDF"}
                        </Button>)}
                      </div>
                    </div>
                    <span>Inspection Detail</span>
                  </h3>
                </div>
              </div>
            </div>
            {/* for inspection info */}
            <div className="row row-xs">
              <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 inspe_hgt">
                <div className="brandList dashboardRightcard d-flex flex-wrap justify-content-between align-items-center">
                  <div className="form-group col-md-3 m-0">
                    <label className="form-label text-left ">
                      Inspection No.
                    </label>
                    <p>#{this.state.inspection_no}</p>
                  </div>
                  <div className="form-group col-md-3 m-0 inspe_hgt">
                    <label className="form-label text-left">In Time</label>
                    <p>{this.state.time_in}</p>
                  </div>
                  <div className="form-group col-md-3 m-0 inspe_hgt">
                    <label className="form-label text-left">Out Time</label>
                    <p>{this.state.time_out}</p>
                  </div>

                  <div className="form-group col-md-3 m-0 inspe_hgt">
                    <label className="form-label text-left">Zone Type</label>
                    <p>{this.state.zone_type}</p>
                  </div>
                  <div className="form-group col-md-3 m-0 inspe_hgt">
                    <label className="form-label text-left">Location</label>
                    <p className="m-0">
                      {this.state.zone_type == "Room" ? this.state.room : ""}
                      {this.state.zone_type == "Commercial Area" ? this.state.commercial : ""}
                      {this.state.zone_type == "Equipment Zone"
                        ? this.state.zone
                        : ""}
                        {this.state.zone_type == "All"
                        ? 'All Zone'
                        : ""}
                    </p>
                  </div>
                  <div className="form-group col-md-3 m-0 inspe_hgt">
                    <label className="form-label text-left">Created By</label>
                    <p className="m-0">{this.state.technician}</p>
                  </div>
                  <div className="form-group col-md-3 m-0 inspe_hgt">
                    <label className="form-label text-left">Checklist</label>
                    <p className="m-0">{this.state.checklist}</p>
                  </div>

                  <div className="form-group col-md-3 m-0 inspe_hgt">
                    <label className="form-label text-left">Property</label>
                    <p className="m-0">{this.state.property_name}</p>
                  </div>
                  <div className="form-group col-md-3 m-0 inspe_hgt">
                    <label className="form-label text-left">Timezone</label>
                    <p className="m-0">{this.state.timezone}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* for task */}

            <div className="row row-xs">
              <div className="col-md-12 mg-t-0 mg-b-10 pd-t-10 pd-b-10">
                <div className="brandSection">
                  <div className="mg-t-0 brandList dashboardRightcard pt-2 pb-2 scroll_mro">
                    <div className="table-responsive tableFormatesimple">
                      <Table bordered className="mg-b-0">
                        <thead>
                          <tr>
                            <th>TASK</th>
                            <th>YES</th>
                            <th>NO</th>
                            <th className="az-inspection-blue">NOTES</th>
                            <th className="az-inspection-red">MRO</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.category
                            ? this.state.category.map((element, index) => (
                                <>
                                  <tr>
                                    <th colSpan="5" className="p-0 border-bottom-0">
                                      <caption>
                                        {element.category_name
                                          ? element.category_name
                                          : ""}
                                      </caption>
                                    </th>
                                  </tr>
                                  {element.tasks
                                    ? element.tasks.map((el, indexes) => (
                                        <>
                                          <tr>
                                            <th scope="row" className="az-inspection-black pl-15 insp_inner_p">
                                              {el.task_name ? <span dangerouslySetInnerHTML={{ __html: this.modifyTaskName(el.task_name) }} /> : ""}
                                            </th>
                                            <td>
                                              {el.task_status == 1 ? (
                                                <label>
                                                <img src={greentick} alt="" />
                                                </label>
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                            <td>
                                              {el.task_status == 2 ? (
                                                <label>
                                                <img src={redtick} alt="" />
                                                </label>
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                            <td>
                                              {el.is_mro_with_note == 2  && el.mro != null? (
                                                <label
                                                  className="log hand-cursor text-primary"
                                                  onClick={() =>
                                                    this.setState({
                                                      showBasicModal: true,
                                                      title: el.mro.title,
                                                      note: el.mro.note,
                                                      mroImages: el.mro.images,
                                                      is_mro: el.is_mro_with_note,
                                                      mro_slug: el.mro.slug,
                                                    })
                                                  }
                                                >
                                                  <img src={bluetick} alt="" className="m-0"/>
                                                </label>
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                            <td>
                                              <code>
                                                {el.is_mro_with_note == 1 && el.mro != null ? (
                                                  <label
                                                  className="log hand-cursor text-primary"
                                                  onClick={() =>
                                                    this.setState({
                                                      showBasicModal: true,
                                                      title: el.mro.title,
                                                      note: el.mro.note,
                                                      is_mro: el.is_mro_with_note,
                                                      mro_slug: el.mro.slug,
                                                      mroImages:
                                                        el.mro.images,
                                                    })
                                                  }
                                                >
                                                  <img src={redtick} alt="" />
                                                </label>
                                                ) : (
                                                  ""
                                                )}
                                              </code>
                                            </td>
                                          </tr>
                                        </>
                                      ))
                                    : ""}
                                </>
                              ))
                            : " "}
                        </tbody>
                      </Table>
                    </div>
                    {/* table-responsive */}
                  </div>
                </div>
              </div>
            </div>
            {/* for mro */}
            <div className="row row-xs">
              <div className="col-md-12 mg-t-0 mg-b-10 pd-t-10 pd-b-10">
                <div className="brandSection">
                  <div className="mg-t-0 brandList dashboardRightcard pt-2 pb-2 scroll_mro">
                    <div className="table-responsive tableFormatesimple">
                      <Table striped className="mg-b-0">
                        <thead>
                          <tr>
                            <th className="border-right-0 wid_50 ">MRO WRITTEN</th>
                            <th className="border-left-0 ">VIEW DETAILS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.category
                            ? this.state.category.map((elm, j) =>
                                elm.tasks
                                  ? elm.tasks.map((el, i) =>
                                      el.is_mro_with_note == 1 && el.mro != null ? (
                                        <tr key={i}>
                                          <th
                                            scope="row"
                                            className="az-inspection-black border-right-0 insp_inner_p"
                                          >
                                            &nbsp;&nbsp;&nbsp;
                                            {el.mro ? <span dangerouslySetInnerHTML={{ __html: this.modifyTaskName(el.mro.title) }} />  : ""}
                                            <Link
                                              to={"/mros/update/" + el.mro.slug}
                                              className="az-inspection-brown"
                                            >
                                              <code>
                                                (
                                                {el.mro
                                                  ? el.mro.mro_number
                                                  : ""}
                                                -MRO
                                              </code>
                                              )
                                            </Link>
                                          </th>
                                          <td className="border-left-0 pl-5">
                                            <i className="mdi mdi-eye-outline text-primary"></i>
                                            <Link
                                              to={"/mros/update/" + el.mro.slug}
                                              className=""
                                            >
                                              <img src={brownArrow} alt="" />
                                            </Link>
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )
                                    )
                                  : ""
                              )
                            : ""}
                            {this.state.isAvail === 0 ? (
                            <tr>
                              <th colSpan={3} className="p-0 border-bottom-0">
                                <caption>No Record Found</caption>
                              </th>
                            </tr>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </Table>
                    </div>
                    {/* bd */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {this.state.showModalImage && (
              <DeleteImageModal
              showModalImage={this.state.showModalImage}
              slug={this.state.imageSlug}
              onRemove={() => this.deleteImage(this.state.imageSlug)}
              onClose={this.onCloseImagePop}
            />
          )}
        <Modal show={this.state.showBasicModal} centered>
          <Modal.Body className={`width_402 p-4 border_radius_14 ${additionalClass}`}>
    <span className="welcomeClose" onClick={() => this.closeModal()}>
      {global.closee}
    </span>

    <h3 className="text-center">Notes</h3>
    <p className="text-center text-center font-weight-bold model_first_p editor_text_box">
      <div dangerouslySetInnerHTML={{ __html: this.modifyTaskName(this.state.title) }} />
    </p> 
    <p className="text-center"><div dangerouslySetInnerHTML={{ __html: this.modifyTaskName(this.state.note) }} /></p>

    <div className="d-flex cnt_center row position-relative mt-3">
      {this.state.mroImages
        ? this.state.mroImages.map((record, i) => {
          return (
            <div key={"Data" + i} className="position-relative">
              <img
                src={record.uri}
                className="note_popup_img hand-cursor"
                alt=""
                onClick={() => this.imageRedirect(record.uri)}
              />
              {global.userPermissions.checkPermission("mros-delete") && (
                <>
                  <button
                    type="button"
                    className="btN btn_remove position-absolute"
                    // style={{ top: '10px', right: '10px' }}
                    onClick={() => this.toggleModal(record.slug)}
                  >
                    <span className="fa fa-times"></span>
                  </button>
                </>
              )}

              {this.state.is_mro &&
              this.state.is_mro == 2 &&
              this.state.is_mro != 1 ? (
                <button
                  type="button"
                  className="btN btn_remove position-absolute"
                  // style={{ top: '10px', right: '10px' }}
                  onClick={() => this.toggleModal(record.slug)}
                >
                  <span className="fa fa-times"></span>
                </button>
              ) : (
                ""
              )}
            </div>
          );
        })
        : ""}
    </div>
    <div className="d-flex cnt_center row mt-3">
      <button
        type="button"
        className="m-0 btn btn-outline-light btn-block"
        onClick={() => this.closeModal()}
      >
        Close
      </button>
      {this.state.is_mro && this.state.is_mro == 1 ? (<>
        <button
          type="button"
          className="m-0 btn btn-outline-light btn-block btn-thene-modal"
          onClick={() => this.mroRedirect()}
        >
          Detail
        </button>
      </>) : ""}
    </div>
  </Modal.Body>
        </Modal>
        <Modal show={this.state.deleteInspModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              <h3>Delete Inspection?</h3>
              <p className="pd-l-50 pd-r-50 pd-b-20 model_first_p">
                Do you really want to delete this Inspection? This process cannot be
                undone.
              </p>
              <div className="d-flex cnt_center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteInspModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteInsp(this.state.slug)}
                  disabled={this.state.isDelete ? true : false}
                >
                  {this.state.isDelete ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default InspectionForm;