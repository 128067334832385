////////////////////////////////////////////////////////////
//  Program: Inspection List.jsx                          //
//  Application:  Inspection  List                        //
//  Option: List of Template                              //
//  Developer: kiran singh                                //
//  Date: 2022-04-06                                      //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import InspectionService from "../../services/InspectionService";
import ChecklistService from "../../services/ChecklistService";
import PropertyService from "../../services/PropertyService";
import { Row, Col, Modal } from "react-bootstrap";
import Moment from "moment";
import UserService from "../../services/UserService";
import back from "./../../assets/images/back.svg";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';
// import {Link} from "react-router-dom";
// const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export class Inspections extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      property_slug: "",
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      total_record: 0,
      hideCommercial: 0,
      hideEquipment: 0,
      hideRoom: 0,
      records: [],
      roomList: [],
      zoneList: [],
      comerList: [],
      userList: [],
      checkList: [],
      zone_type: "",
      user_slug: "",
      checklist_slug: "",
      isFilterTrue: false,
      config: {
        sort: {
          column: "created_at",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
          filter: "Search by inspection no.",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "inspection_no",
          text: "Inspection No.",
          className: "inspection_no hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.inspection_no ? "#" + record.inspection_no : "";
          },
        },
        {
          key: "time_out",
          text: "Time Out",
          className: "time_out hand-cursor",
          sortable: true,
          cell: (record) => {
            return moment(moment.utc(record.completed_on).toDate()).local().format("DD MMM, YY - hh:mm A");
          },
        },
        {
          key: "location_name",
          text: "Location",
          className: "location hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.location_name ? record.location_name : "";
           
          },
        },
        {
          key: "zone_type",
          text: "Zone",
          className: "zone_type hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.zone_type ? record.zone_type.zone_name : "";
          },
        },
        {
          key: "technician",
          text: "created By",
          className: "technician hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.inspector ? record.inspector.full_name : "";
          },
        },
        {
          key: "property",
          text: "Property",
          className: "property hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.property ? record.property.property_name : "";
          },
        },
        {
          key: "checklist",
          text: "Checklist",
          className: "checklist hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.checklist ? record.checklist.checklist_name : "";
          },
        },
      ],
      isFirstLoad: true,
      lastQueryString: "",
      singleScreen: false,
      fistLoad: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetData = this.resetData.bind(this);
    this.customFilter = this.customFilter.bind(this);
  }
  componentDidMount() {
    const savedFilterState = JSON.parse(localStorage.getItem('inspectionFilterState'));

    if (savedFilterState) {
      this.setState({
        zone_type: savedFilterState.zone_type,
        user_slug: savedFilterState.user_slug,
        checklist_slug: savedFilterState.checklist_slug,
        property_slug: savedFilterState.property_slug,
        out_time: savedFilterState.out_time ? new Date(savedFilterState.out_time) : null,
        selectedZoneTypeList: savedFilterState.selectedZoneTypeList,
        selectedCheckList: savedFilterState.selectedCheckList,
        selectedUserList: savedFilterState.selectedUserList,
        selectedProperty: savedFilterState.selectedProperty,
        isFilterTrue: true,
      }, () => {
        let queryString =
          "is_filter=1&" +
          "zone_type=" + (this.state.zone_type ? this.state.zone_type : '') +
          "&user_slug=" + (this.state.user_slug ? this.state.user_slug : '') +
          "&checklist_slug=" + (this.state.checklist_slug ? this.state.checklist_slug : '') +
          "&property_slug=" + (this.state.property_slug ? this.state.property_slug : '') +
          "&out_time=" + (this.state.out_time ? Moment(this.state.out_time).format("YYYY-MM-DD") : '');
        this.getData(queryString);
        this.setState({ tableQueryString: "", customQueryString: queryString });
        this.getUserList();
        this.getCheckList();
        this.getProperty();
      });
    } else {
      this.getData();
      this.getUserList();
      this.getCheckList();
      this.getProperty();
      this.setState({ zoneTypeList: global.zoneTypeList });
    }
  }
  async getData(queryString = "") {
    this.setState({ showSpinner: true });
    if (this.state.isFirstLoad) {
      this.setState({ loading: true, isFirstLoad: false });
    }
    let propertySlug = "";
    let propertyName = "";
    let localSelectedProperty = "";
    if (localStorage.getItem("activePropertyCount") == 1) {
      localSelectedProperty = {
        label: localStorage.getItem("singlePropertyName"),
        value: localStorage.getItem("singlePorpertySlug"),
        };
    } else {
      if (this.state.singleScreen === true) {
        localSelectedProperty = this.state.selectedProperty;
          if (queryString == "resetCall") {
            localSelectedProperty = [];
          }
      } else {
        if (localStorage.getItem("outSidePorpertySlug") !== undefined &&
        localStorage.getItem("outSidePorpertySlug") !== null &&
          localStorage.getItem("outSidePorpertySlug") !== "") {
        propertySlug = localStorage.getItem("outSidePorpertySlug");
        propertyName = localStorage.getItem("selectedPropertyName");
        if (this.state.fistLoad === false) {
          queryString = "property_slug=" + propertySlug;
          this.setState({fistLoad:true});
        } else {
          queryString = queryString + "&property_slug=" + propertySlug;
        }
         localSelectedProperty = {
          label: propertyName,
          value: propertySlug,
        };
        } else {
          localSelectedProperty = this.state.selectedProperty;
          if (queryString == "resetCall") {
            localSelectedProperty = [];
          }
          
      }
      }
     
    }
    var list = [];
    var totalRecords = 0;
    let res = await InspectionService.getInspectionList(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
      totalRecords = res.data.total ? res.data.total : 0;
    }
    if (queryString !== "resetCall") {
      this.setState({ showFilter: false });
    }
    this.setState({
      loading: false,
      isFilterTrue: false,
      records: list,
      total_record: totalRecords,
      selectedProperty:localSelectedProperty,
      showSpinner: false
    });

    this.setState({
      completed_on: Moment(list.completed_on).format("DD MMM, YY - hh:mm A"),
    });
  }
  async getProperty(queryString = "") {
    var propertyList = [];
    var propertyListData = [];
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
    }
    if (propertyList.length > 0) {
      propertyList.forEach((item) => {
        propertyListData.push({
          label: item.property_name,
          value: item.slug,
        });
      });
    }
    this.setState({
      propertyList: propertyListData,
    });
  }
  async getCheckList(queryString = "") {
    var list = [];
    var listData = [];
    let res = await ChecklistService.getCheckList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
    }
    list.forEach((item) => {
      listData.push({
        label: item.checklist_name,
        value: item.slug,
      });
    });
    this.setState({
      checkList: listData,
    });
  }
  // Get Users List
  async getUserList(queryString = "") {
    this.setState({ loading: true });
    var userList = [];
    var userListData = [];
    let res = await UserService.getUserList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      userList = res.data ? res.data : [];
    }

    userList.forEach((item) => {
     // if (item.role_id >= global.roleTypeId) {
        userListData.push({
          label: item.full_name,
          value: item.slug,
        });
      //}
    });

    this.setState({ userList: userListData });
    this.setState({ loading: false });
  }
  customFilter() {
    let date = "";
    if (this.state.out_time) {
      date = Moment(this.state.out_time).format("YYYY-MM-DD");
    }
    let queryString =
    "is_filter=1&" +
    "zone_type=" + (this.state.zone_type ? this.state.zone_type : '') +
    "&user_slug=" + (this.state.user_slug ? this.state.user_slug : '') +
    "&checklist_slug=" + (this.state.checklist_slug ? this.state.checklist_slug : '') +
    "&property_slug=" + (this.state.property_slug ? this.state.property_slug : '') +
    "&out_time=" + (date ? date : '');
    this.setState({ lastQueryString: queryString });
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.setState({ isFilterTrue: true });
     // Save the filter state to local storage
    localStorage.setItem('inspectionFilterState', JSON.stringify({
      zone_type: this.state.zone_type,
      user_slug: this.state.user_slug,
      checklist_slug: this.state.checklist_slug,
      property_slug: this.state.property_slug,
      out_time: this.state.out_time,
      selectedZoneTypeList: this.state.selectedZoneTypeList,
      selectedCheckList: this.state.selectedCheckList,
      selectedUserList: this.state.selectedUserList,
      selectedProperty: this.state.selectedProperty,
    }));
    this.getData(queryString);
    if (this.state.showFilter === 1) {
      document.body.classList.remove("bdy_over");
    }
  }

  resetData() {
    this.setState({
      customQueryString: "",
      queryString: "",
      zone_type: "",
      user_slug: "",
      checklist_slug: "",
      property_slug: "",
      date: "",
      lastQueryString: "",

    });
    this.setState({
      selectedZoneTypeList: "",
      selectedCheckList: [],
      selectedUserList: "",
      out_time: "",
      selectedProperty: "",
      singleScreenProperty: "",
      singleScreen: false,
    });
    localStorage.removeItem('inspectionFilterState');
    this.getData("resetCall");
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
      document.body.classList.remove("bdy_over");
    } else {
      this.setState({ zoneTypeList: global.zoneTypeList });
      this.setState({ showFilter: 1 });
      document.body.classList.add("bdy_over");
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
  
    // Function to create query parameters
    const createQueryParams = (data) => {
      const params = new URLSearchParams();
      Object.keys(data).forEach((key) => {
        if (key === "sort_order" && data[key]) {
          params.append("sort_order", data[key].order);
          params.append("sort_column", data[key].column);
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          }
          params.append(newKey, data[key]);
        }
      });
      
      return params.toString();
    };
    
    const pageSize = Number(data.page_size);
    const validPageSizes = [10,20, 50, 100];

    let possible = this.state.total_record / pageSize + (this.state.total_record % pageSize ? 1 : 0);

    if (data.page_number > possible || ( pageSize && !validPageSizes.includes(pageSize))) {
      data.page_number = 1; 
    }
    let queryString = createQueryParams(data);
  
    if (data.filter_value === "") {
      queryString += `&${this.state.customQueryString}`;
    }
  
    this.setState({ tableQueryString: queryString });
  
    if (data.filter_value !== this.state.filter_value) {  
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      if (this.state.firstLoad === true) {
        queryString = new URLSearchParams({
          page: data.page_number,
          page_size: data.page_size,
          sort_order: data.sort_order.order,
          sort_column: data.sort_order.column,
        }).toString();
      }
  
      this.getData(`${queryString}${this.state.lastQueryString}`);
    }
  };


  customValidate() {
    let isValid = true;
    return isValid;
  }
  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push("/inspection/update/" + data.slug);
  };
  //zone type handle
  handleChangeZoneType(value) {
    let zone_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        zone_type.push(item.value);
      });
    }
    this.setState({
      selectedZoneTypeList: value,
      zone_type: value.value,
    });
  }
  //handleChangeCheckList
  handleChangeCheckList(value) {
    let checklist_slug = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        checklist_slug.push(item.value);
      });
    }
    this.setState({
      selectedCheckList: value,
      checklist_slug: value.value,
    });
  }
  // Technician/User Change Handler
  handleChangeUserList(value) {
    let user_slug = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        user_slug.push(item.value);
      });
    }
    this.setState({
      selectedUserList: value,
      user_slug: value.value,
    });
  }

  //property type handle
  handleChangeProperty(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    this.setState({
      selectedProperty: value,
      property_slug: value.value,
      singleScreen: (localStorage.getItem("outSidePorpertySlug") !== undefined &&
      localStorage.getItem("outSidePorpertySlug") !== null &&
        localStorage.getItem("outSidePorpertySlug") !== "" ? true:false),
      singleScreenProperty: value.label,
    });
  }
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    return (
      <div>
        <main>
        {this.state.showSpinner && (
            <div className="spinnerLoader1">
              <div className="toploader1">
                <img src={require("../../assets/images/Loader-new.gif").default} alt="Loading" />
              </div>
            </div>
          )}
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div
                  className="dashbaord-pageHeadertitle"
                >
                  <h3 className="flex-column">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h3>Inspections</h3>
                      <div className="btnNavigate d-flex justify-content-end">
                        <button
                          onClick={this.filterToggle}
                          className="gf-primary-btn-small-one"
                        >
                          {" "}
                          {this.state.showFilter === 1 ? "Filter" : "Filter"}
                        </button>
                      </div>
                    </div>
                    <span>All Inspections</span>
                  </h3>
                </div>

                <div className="brandSection">
                  {this.state.showFilter ? (
                    <Row className="align-items-start fliter_back">
                      <div className="order_form">
                        <div className="d-flex justify-content-between align-items-center w-100 ">
                          <h3
                            className="hand-cursor back-size"
                            onClick={this.filterToggle}
                          >
                            <img src={back} />
                            Back
                          </h3>
                        </div>

                        <h3>Filter</h3>
                        <div className="filter-box-scroll">
                        <Row className="align-items-start justify-content-between m-0 p-0">
                          <label className="form-label text-left mg-b-5 mt-0">
                            zone
                          </label>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              value={this.state.selectedZoneTypeList}
                              options={this.state.zoneTypeList}
                              isSearchable={true}
                              onChange={(value) =>
                                this.handleChangeZoneType(value)
                              }
                              defaultValue={this.state.selectedZoneTypeList}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                          </Col>
                          <label className="form-label text-left mg-b-5">
                            Checklist
                          </label>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              value={this.state.selectedCheckList}
                              options={this.state.checkList}
                              onChange={(value) =>
                                this.handleChangeCheckList(value)
                              }
                              defaultValue={this.state.selectedCheckList}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                          </Col>
                          <label className="form-label text-left mg-b-5">
                            Created By
                          </label>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              value={this.state.selectedUserList}
                              options={this.state.userList}
                              onChange={(value) =>
                                this.handleChangeUserList(value)
                              }
                              defaultValue={this.state.selectedUserList}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                          </Col>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <label className="form-label text-left mg-b-5 mg-b-5">
                              Property
                            </label>
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              value={this.state.selectedProperty}
                              options={this.state.propertyList}
                              isSearchable={true}
                              onChange={(value) =>
                                this.handleChangeProperty(value)
                              }
                              defaultValue={this.state.selectedProperty}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                          </Col>
                          <label className="form-label text-left mg-b-5">
                            Time Out
                          </label>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <DatePicker
                              className="form-control max_width_100"
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              autoComplete="off"
                              yearDropdownItemNumber={
                                global.yearDropdownItemNumber
                              }
                              name="out_time"
                              placeholderText="dd-mm-yy"
                              dateFormat="dd MMM yy"
                              selected={this.state.out_time}
                              onChange={(data) =>
                                this.changeHandler({
                                  target: {
                                    type: "date",
                                    name: "out_time",
                                    value: data,
                                  },
                                })
                              }
                            />
                          </Col>

                          <Row className="row col-md-12 mg-t-30 justify-content-between w-100 pr-0">
                            <button
                              onClick={this.resetData}
                              className="btn-success-outline"
                            >
                              Reset
                            </button>
                            <button
                              onClick={this.customFilter}
                              className="btn-success mg-l-0 mg-r-20"
                            >
                              {this.state.isFilterTrue
                                ? global.loader
                                : "Search"}
                            </button>
                          </Row>
                        </Row>
                        </div>
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className=" brandList dashboardRightcard">
            {localStorage.getItem("outSidePorpertySlug") ? (<>
                <div className="row">
                <div className="col-md-12">
                    <div className="checklist_table_heading "><span className="lspan">Search Applied On : </span><span className="rspan">{this.state.singleScreenProperty ? this.state.singleScreenProperty:localStorage.getItem("selectedPropertyName") }</span></div>
                </div>
              </div>
              </>) :""}
              <ReactDatatable
                className="table no-wrap mg-b-30 pd-b-2"
                config={this.state.config}
                records={this.state.records}
                columns={this.state.columns}
                extraButtons={this.state.extraButtons}
                dynamic={true}
                loading={this.state.loading}
                total_record={this.state.total_record}
                onChange={this.tableChangeHandler}
                onRowClicked={this.rowClickedHandler}
              />
            </div>
          </div>
        </main>
        {/* Detail popup Modal */}
        <Modal className="" show={this.state.addCostPayableAtModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <h3 className="text-center">Log</h3>
            <p className="text-center font-weight-bold">
              DAILY LOG {this.state.row_index}
            </p>
            <p className="text-center">
              {this.state.created_date} . {this.state.user_name}
            </p>
            <p className="text-center">{this.state.note}</p>

            <div className="d-flex justify-content-between mt-3 row">
              <button
                type="button"
                className="mx-auto m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeLogDetailAtModal()}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Inspections;
