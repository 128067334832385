import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import QuickLinkService from "../../services/QuickLinkService";
// import { NavLink } from "react-router-dom";
// import LinkImg from "../../assets/images/Links.svg";
import placeholderImage from "../../assets/images/image-placeholder.png";

export class QuickLinks extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      records: [],
      config: {
        sort: {
          column: "title",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
          filter: "Search by title",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "title",
          text: "Title",
          className: "customer_logo",
          sortable: false,
          cell: (record) => {
            return record && record.title ? record.title : "";
          },
        },
        {
          key: "url",
          text: "URL",
          className: "customer_name hand-cursor",
          sortable: false,
          cell: (record) => {
            let url = record && record.url ? record.url : placeholderImage;

            return (<a target="_blank" rel="noreferrer" href={url}>{url}</a>);

          },
        },

      ],
      isFirstLoad: true,
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetDate = this.resetDate.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true });
    if (this.state.isFirstLoad) {
      this.setState({ loading: true, isFirstLoad: false });
    }
    var list = [];
    var totalRecords = 0;
    let res = await QuickLinkService.getAllActiveLink(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
      totalRecords = res.data.total ? res.data.total : 0;
    }
    this.setState({
      loading: false,
      total_record: totalRecords,
      records: list,
      showSpinner: false,
    });
  }

  customFilter() {
    let queryString = "filter_value" + this.state.filter_value + "&is_custom=1";
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetDate() {
    this.setState({ start_date: "" });
    this.setState({ end_date: "" });
    this.getData();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
      document.body.classList.add('bdy_over');
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          } else if (key === "page_size") {
            newKey = "per_page";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.getData(queryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  render() {
    return (
      <div>
        <main>
        {this.state.showSpinner && (
            <div className="spinnerLoader1">
            <div className="toploader1">
                <img src={require("../../assets/images/Loader-new.gif").default} alt="Loading" />
            </div>
            </div>
        )}
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                  <div className="dashbaord-pageHeadertitle">
                    <h3 className="flex-column">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <h3>Quick Links</h3>
                        <div className="btnNavigate invisible">
                        <a href="/quick-links/add"><button className="gf-primary-btn-small-one">Add Quick Links</button></a>
                        </div>
                        </div>
                      <span>All Quick Links</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="mg-tneg-4 brandList dashboardRightcard link_page_sec">
              <div className="quick_links_sec">
                <ul>
                  {this.state.total_record && this.state.total_record > 0
                    ? this.state.records.map((data) => (
                      <li key={data.id}>
                        <a href={data.url} target="_blank" rel="noreferrer">
                          <div className="quick_links_cont">
                            <div className="quick_img_box">
                              <img src={data.image_url ? data.image_url : placeholderImage} alt="logo" />
                              <h3>{data.title}</h3>
                              {/* <h3>Lorem ipsum dolor sit amet, </h3> */}
                            </div>
                            <div className="quick_overlay">
                              <div className="quick_text">
                                <p>{data.description}</p>
                                {/* <p>Set in the city centre, this luxe hotel is a 10-minute walk from POI1, 15 minutes by foot from PO2 and 1 km from PO!3.</p> */}
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                    ))
                    : ""}
                </ul>
              </div>
            </div>
          </div>
        </main>
      </div>
    );

  }
}



export default QuickLinks;