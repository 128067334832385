/*
 * Program: ActionItemForm.jsx
 * Application: Action Item
 * Option: to Add/Update Action Item Information
 * Developer: Chandra Shekhar
 * Date: 2022-04-01
 */
import React, { Component } from "react";
import { Form, Modal, Tab, Nav } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import Alerts from "../common/Alerts";
import Select from "react-select";
import DatePicker from "react-datepicker";
import UserService from "../../services/UserService";
import Moment from "moment";
import FileWidget from "../fileUploader/FileWidget";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import { Link } from "react-router-dom";
import back from "./../../assets/images/back.svg";
import placeholderImage from "./../../assets/images/image-placeholder.png";
import ActionItemService from "../../services/ActionItemService";
import ActionItemNotes from "./ActionItemNotes";
import PropertyService from "../../services/PropertyService";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomToolbar from "../CustomToolbar";
import LinkModal from "../LinkModal";
import DeleteImageModal from "../DeleteImageModal";

export class ActionItemForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.quillRef = React.createRef();
    this.state = {
      showModal: false,
      showModalImage: false,
      range: null,
      url: "",
      text: "",
      isEditing: false,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      slug: this.props.match.params.slug,
      isSubmit: false,
      isDelete: false,
      sort_order: "",
      title: "",
      note: "",
      priority_type: "",
      priorityType: [],
      selectedPriorityType: [],
      status_id: "",
      statusList: [],
      selectedStatusList: [],
      userList: [],
      selectedUserList: [],
      user_slug: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData")).slug
        : "",
      due_date: "",
      newRelatedFilesDesc: [],
      activeClass: "info",
      isRefresh: false,
      actionNumber: "",
      createdBy: "",
      createdAt: "",
      auditNumber: "",
      updatedAt: "",
      imageData: [],
      actionItemImages: [],
      deletedFileList: [],
      property: "",
      propertyName: "",
      property_slug: "",
      propertyList: [],
      selectedPropertyList: [],
    };
    this.initialState = this.state;
    this.defaultData = {};
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateMro = this.saveOrUpdateMro.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.deleteActionItem = this.deleteActionItem.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.opendeleteMroModal = this.opendeleteMroModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleModalRemove = this.handleModalRemove.bind(this);
    this.handleLink = this.handleLink.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
    this.onCloseImagePop = this.onCloseImagePop.bind(this);
  }

  componentDidMount() {
    this.getActionItem();
    // this.getUserList();
    this.getPropertyList();
    const quill = this.quillRef.current.getEditor();
    quill.getModule("toolbar").addHandler("link", this.handleLink);

    // Add event listener for clicks on links
    quill.root.addEventListener("click", this.handleLinkClick);
  }

  componentWillUnmount() {
    const quill = this.quillRef.current.getEditor();
    quill.root.removeEventListener("click", this.handleLinkClick);
  }

  handleLink() {
    const quill = this.quillRef.current.getEditor();
    const range = quill.getSelection();
    if (range) {
      this.setState({
        showModal: true,
        range,
        url: "",
        text: "",
        isEditing: false,
      });
    }
  }

  handleModalClose() {
    this.setState({
      showModal: false,
      range: null,
      url: "",
      text: "",
      isEditing: false,
    });
  }

  handleModalSubmit(url, text) {
    const quill = this.quillRef.current.getEditor();
    const { range } = this.state;
    if (range && url && text) {
      quill.deleteText(range.index, range.length);
      quill.insertText(range.index, text, "link", url);
      quill.setSelection(range.index + text.length);
    }
    this.setState({
      showModal: false,
      range: null,
      url: "",
      text: "",
      isEditing: false,
    });
  }

  handleLinkClick(event) {
    if (event.target.tagName === "A") {
      if (event.ctrlKey || event.metaKey) {
        // Open the URL in a new tab
        const url = event.target.getAttribute("href");
        window.open(url, "_blank");
      } else {
        event.preventDefault();
        const quill = this.quillRef.current.getEditor();
        const range = quill.getSelection();
        const url = event.target.getAttribute("href");
        const text = event.target.innerText;

        // Prevent Quill's tooltip from showing
        quill.blur();

        this.setState({
          showModal: true,
          range: { index: range.index, length: text.length },
          url,
          text,
          isEditing: true,
        });
      }
    }
  }

  handleModalRemove() {
    const quill = this.quillRef.current.getEditor();
    const textLength = quill.getLength();
    // let range = { index: 0, length: textLength };

    // Get the entire content and iterate over each character
    for (let i = 0; i < textLength; i++) {
      const [link] = quill.getFormat(i, 1).link || [];
      if (link) {
        // Remove the link format only, leaving the text intact
        quill.formatText(i, 1, "link", false);
      }
    }

    this.setState({ showModal: false, url: "", text: "", isEditing: false });
  }

  getActiveClass(e) {
    if (e === "notes") {
      this.setState({ isRefresh: true });
    } else {
      this.setState({ isRefresh: false });
    }
    this.setState({ activeClass: e });
  }

  // Get Users List
  async getUserList(queryString = "") {
    this.setState({ loading: true });
    var userList = [];
    var userListData = [];
    let res = await UserService.getUserList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      userList = res.data ? res.data : [];
    }

    userList.forEach((item) => {
      //  if (item.role_id >= global.roleTypeId) {
      userListData.push({
        label: item.full_name,
        value: item.slug,
      });
      // }
    });
    this.setState({ userList: userListData });
    this.setState({ loading: false });
  }

  async getActionItem() {
    this.setState({ loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await ActionItemService.getActionItem(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.due_date = res.data.due_date
          ? Moment(res.data.due_date).toDate("dd-MM-YYYY")
          : null;
        resData.note = res.data && res.data.note ? res.data.note : "";
        resData.user_slug =
          res.data && res.data.assignee && res.data.assignee.slug
            ? res.data.assignee.slug
            : "";
        resData.property_slug =
          res.data && res.data.property && res.data.property.slug
            ? res.data.property.slug
            : "";
        resData.property = res.data.property ? res.data.property : "";
        if (resData.property) {
          this.setState({ propertyName: resData.property.property_name });
        }
        if (resData.action_number) {
          this.setState({
            actionNumber: resData.action_number,
            actionItemImages: resData.images,
          });
        }

        if (resData.audit) {
          if (resData.audit.get_audit) {
            this.setState({
              auditNumber: "#" + resData.audit.get_audit.audit_no,
            });
          }
        }

        if (resData.updated_at) {
          this.setState({
            updatedAt: Moment(resData.updated_at).format("DD MMM, YY hh:mm A "),
          });
        }

        if (resData.created_by) {
          this.setState({ createdBy: resData.created_by.full_name });
        }

        if (resData.created_at) {
          this.setState({
            createdAt: Moment(resData.created_at).format(
              "DD MMM, YY - hh:mm A "
            ),
          });
        }
        // Priority  Select
        var selectedPriority = [];
        global.priorityType.forEach((item, i) => {
          if (parseInt(item.value) === parseInt(res.data.priority_type)) {
            selectedPriority = [
              {
                value: item.value,
                label: item.label,
              },
            ];
            return false;
          }
        });

        // Property Select
        var selectedProperty = [];
        selectedProperty = res.data.property
          ? [
              {
                value: res.data.property.slug,
                label: res.data.property.property_name,
              },
            ]
          : "";

        // Status  Select
        var selectedStatus = [];
        global.actionItemStatus.forEach((item, i) => {
          if (parseInt(item.value) === parseInt(res.data.status_id)) {
            selectedStatus = [
              {
                value: item.value,
                label: item.label,
              },
            ];
            return false;
          }
        });

        // Technician/User Select
        var selectedUser = [];
        selectedUser = res.data.assignee
          ? [
              {
                value: res.data.assignee.slug,
                label: res.data.assignee.full_name,
              },
            ]
          : "";
      }
      this.setState(resData);
      this.setState({
        selectedPriorityType: selectedPriority,
        selectedPropertyList: selectedProperty,
      });
      this.setState({ selectedStatusList: selectedStatus });
      this.setState({ selectedUserList: selectedUser });
      this.setState({ input: resData });
      this.defaultData = resData;
      var queryString = "property_slug=" + (resData ? resData?.property_slug : "") + "&action_item=1";
      this.getUserList(queryString);
    }

    this.setState({ priorityType: global.priorityType });
    this.setState({ statusList: global.actionItemStatus });
    this.setState({ loading: false });
    queryString = "property_slug=" + (resData?.property_slug ? resData.property_slug : "");
    this.getUserList(queryString);
  }

  // Get Property List
  async getPropertyList(queryString = "") {
    this.setState({ loading: true });
    var propertyList = [];
    var propertyListData = [];
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
    }
    propertyList.forEach((item) => {
      propertyListData.push({
        label: item.property_name,
        value: item.slug,
      });
    });
    this.setState({ propertyList: propertyListData });
    this.setState({ loading: false });
  }
  // Save and Update Method Start
  saveOrUpdateMro = async (e) => {
    e.preventDefault();
    this.setState({
      alertModalMessage: "",
    });
    //Image Section Start

    const FileWidgetObj = new FileWidget();
    this.setState({ loading: true, isSubmit: true });
    const output = await FileWidgetObj.fileUploadFun("action_item_form");
    let inputFilesData = [];
    if (output.status !== false) {
      if (output.response[0].filesData) {
        if (output.response[0].filesData.length > 0) {
          inputFilesData = {
            availableFiles: output.response,
            deletedFiles: this.state.deletedFileList,
          };
        }
      }
      if (this.validator.allValid() && this.customValidate()) {
        this.setState({ loading: true, isSubmit: true });
        let inputData = {
          title: this.state.title,
          note: this.state.note,
          priority_type: this.state.priority_type,
          user_slug: this.state.user_slug,
          due_date: this.state.due_date
            ? Moment(this.state.due_date).format("YYYY-MM-DD")
            : null,
          status_id: this.state.status_id,
          imageData: inputFilesData,
          deletedFiles: this.state.deletedFileList,
          property_slug: this.state.property_slug,
        };

        if (this.state.slug !== undefined) {
          let res = await ActionItemService.updateActionItem(
            this.state.slug,
            inputData
          );
          if (global.successStatus.includes(res.status)) {
            this.setState({
              showAlertModal: true,
              alertModalType: "success",
              alertModalTitle: "Success",
              alertModalMessage: res.message ? res.message : "Success",
            });
            setTimeout(
              () => this.props.history.push("/action-item"),
              global.redirect_time
            );
          } else {
            let alertMessage = "";
            if (res.data.result.errorDetail !== null) {
              let errors = {};
              res.data.errorCode = "Validation Failed";
              res.data.result.errorDetail.forEach((item) => {
                errors[item.errorField] = item.errorMessage[0];
                res.data.errorMessage = "";
              });
              this.setState({ errors: errors });
            }
            this.setState({
              showAlertModal: true,
              alertModalType: "error",
              alertModalTitle: res.data.message ? res.data.message : "Error!",
              alertModalMessage:
                alertMessage !== "" ? alertMessage : res.data.message,
            });
          }
        } else {
          this.setState({ loading: true, isSubmit: true });
          let res = await ActionItemService.addActionItem(inputData);
          if (global.successStatus.includes(res.status)) {
            this.setState({
              showAlertModal: true,
              alertModalType: "success",
              alertModalTitle: "Success",
              alertModalMessage: res.message ? res.message : "Success",
            });
            setTimeout(
              () => this.props.history.push("/action-item"),
              global.redirect_time
            );
          } else {
            let alertMessage = "";
            if (res.data.result.errorDetail !== null) {
              let errors = {};
              res.data.errorCode = "Validation Failed";
              res.data.result.errorDetail.forEach((item) => {
                errors[item.errorField] = item.errorMessage[0];
                res.data.errorMessage = "";
              });
              this.setState({ errors: errors });
            }
            this.setState({
              showAlertModal: true,
              alertModalType: "error",
              alertModalTitle: res.data.message ? res.data.message : "Error!",
              alertModalMessage:
                alertMessage !== "" ? alertMessage : res.data.message,
            });
          }
        }
        this.setState({ loading: false, isSubmit: false });
        setTimeout(
          () => this.setState({ showAlertModal: false }),
          global.alert_close_time
        );
      } else {
        this.setState({ loading: false, isSubmit: false });
        this.validator.showMessages();
      }
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: "Error!",
        alertModalMessage: output.response[0].validationMessage[0][0],
        loading: false,
        isSubmit: false,
      });
      setTimeout(
        () =>
          this.setState({
            showAlertModal: false,
            loading: false,
            isSubmit: false,
          }),
        global.alert_close_time
      );
    }
    //Image Section End
  };
  // Save and Update Method End

  opendeleteMroModal(slug) {
    this.setState({ deleteMroModal: true });
  }

  closedeleteMroModal() {
    this.setState({ deleteMroModal: false });
  }

  // Delete Method Start
  async deleteActionItem(slug) {
    this.setState({ loading: true, isDelete: true });
    let res = await ActionItemService.deleteActionItem(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteMroModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.props.history.push("/action-item/"),
        global.redirect_time
      );
    } else {
      this.closedeleteMroModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false, isDelete: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  // Delete Method End

  // Delete MRO Image Start
  async deleteImage(slug) {
    this.setState({ loading: true, isSubmit: true });
    let res = await ActionItemService.deleteImage(slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
        showModalImage: false,
      });
      this.getActionItem();
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  // Delete MRO Image End

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;

    if (typeof value === "string") {
      // Remove HTML tags using a regular expression
      value = value.replace(/<\/?[^>]+(>|$)/g, "");
    }

    // Update the input object and state with the sanitized value
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Call the validation methods
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
    this.validator.hideMessages();
    if (!this.state.status_id) {
      this.validator.showMessages();
    } else {
      this.validator.showMessageFor("customer_name");
      this.validator.showMessageFor("customer_logo");
    }
  }

  handleChange(value) {
    this.setState({ note: value }, () => {
      this.customValidate();
    });
  }

  // Priority  Change Handler
  handleChangePriority(value) {
    let priority_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        priority_type.push(item.value);
      });
    }
    this.setState({
      selectedPriorityType: value,
      priority_type: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  // Status Change Handler
  handleChangeStatus(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    this.setState({
      selectedStatusList: value,
      status_id: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  // Technician/User Change Handler
  handleChangeUserList(value) {
    let user_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        user_slug.push(item.value);
      });
    }
    this.setState({
      selectedUserList: value,
      user_slug: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  // Property Change Handler
  handleChangeProperty(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    this.setState({
      selectedZoneTypeList: "",
      selectedPropertyList: value,
      property_slug: value.value,
    });
    var queryString = "property_slug=" + (value.value ? value.value : "") + "&action_item=1";
    this.getUserList(queryString);
    this.setState({ confirm_back: 1 });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  toggleModal(slug) {
    // this.setState({ showModalImage: !this.state.showModalImage, imageSlug:slug });
    this.setState({
      showModalImage: true,
      imageSlug: slug,
    });
  }
  onCloseImagePop() {
    this.setState({ showModalImage: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Update MRO";
    } else {
      return "Add New MRO";
    }
  }
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    const modules = {
      toolbar: {
        container: "#toolbar",
        handlers: {
          link: this.handleLink,
        },
      },
    };
    return (
      <div>
        <main>
          {this.state.showSpinner ? global.spinnerLoader : ""}
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                  <div className="dashbaord-pageHeadertitle heading_alignment">
                    <h3 className="flex-column">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        Action Item
                        <Link to="/action-item">
                          <img src={back} alt="" />
                          Back
                        </Link>
                      </div>
                      <span>
                        {this.state.slug !== undefined ? (
                          <>UPDATE Action Item</>
                        ) : (
                          <>Add Action Item</>
                        )}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 pl-0 pr-0">
              <Alerts
                show={this.state.showAlertModal}
                type={this.state.alertModalType}
                title={this.state.alertModalTitle}
                message={this.state.alertModalMessage}
              />
            </div>
            {/* Nav Bar start */}
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="info"
              onSelect={(e) => this.getActiveClass(e)}
              eventKey={this.state.activeClass}
            >
              {this.state.slug !== undefined ? (
                <div className="scrolling-carousel pd-l-0">
                  <ScrollingCarousel rightArrow={true} leftArrow={true}>
                    <div className="swipesection">
                      <Nav variant="pills" className="">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="info"
                            className={
                              this.state.activeClass === "info" ? "active" : ""
                            }
                          >
                            Info
                          </Nav.Link>
                        </Nav.Item>
                        {global.userPermissions.checkPermission(
                          "action_items-notes"
                        ) && (
                          <Nav.Item>
                            <Nav.Link
                              eventKey="notes"
                              className={
                                this.state.activeClass === "notes"
                                  ? "active"
                                  : ""
                              }
                            >
                              Notes
                            </Nav.Link>
                          </Nav.Item>
                        )}
                      </Nav>
                    </div>
                  </ScrollingCarousel>
                </div>
              ) : (
                ""
              )}
              <Tab.Content>
                <Tab.Pane eventKey="notes">
                  {this.state.isRefresh && (
                    <ActionItemNotes mro_slug={this.state.slug} />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="info">
                  <div className="col-md-12 mg-t-18 p-5 dashboardRightcard mb-5">
                    {this.state.slug !== undefined ? (
                      <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-80 pd-r-70">
                        <div className="col-md-3 pl-0">
                          <label className="form-label text-left mg-b-5">
                            Action Item No.
                          </label>
                          <p>{this.state.actionNumber ?? ""}</p>
                        </div>
                        <div className="col-md-3">
                          <label className="form-label text-left mg-b-5">
                            Audit No.
                          </label>
                          <p>{this.state.auditNumber ?? ""}</p>
                        </div>
                        <div className="col-md-3">
                          <label className="form-label text-left mg-b-5">
                            Created By
                          </label>
                          <p>
                            {this.state.createdAt && this.state.createdBy
                              ? this.state.createdAt +
                                " by " +
                                this.state.createdBy
                              : ""}
                          </p>
                        </div>
                        <div className="col-md-3">
                          <label className="form-label text-left mg-b-5">
                            Last Update
                          </label>
                          <p>
                            {this.state.updatedAt ? this.state.updatedAt : ""}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="tabNavigation">
                      <form
                        id="action_item_form"
                        onSubmit={this.onSubmitFun}
                        method="post"
                        encType="multipart/form-data"
                      >
                        <div className="mg-t-0 mg-b-0">
                          <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                            {/* Title */}
                            <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left mg-b-5">
                                Title{" "}
                              </label>
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="title"
                                value={
                                  this.state.title
                                    ? this.state.title.replace(
                                        /<\/?[^>]+(>|$)/g,
                                        ""
                                      )
                                    : ""
                                }
                                maxLength="50"
                                tabIndex="1"
                                autoComplete="off"
                              />
                              {this.state.errors.title ? (
                                <div className="text-danger">
                                  {this.state.errors.title ?? ""}
                                </div>
                              ) : (
                                this.validator.message(
                                  "title",
                                  this.state.title,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {/*  Note  */}
                            <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left mg-b-5">
                                Note
                              </label>
                              {/* <Form.Control
                                type="text"
                                as="textarea"
                                onChange={this.changeHandler}
                                name="note"
                                value={this.state.note ?? ""}
                                maxLength="500"
                                tabIndex="2"
                                placeholder="Type here..."
                              />
                              {this.state.errors.note ? (
                                <div className="text-danger">
                                  {this.state.errors.note}
                                </div>
                              ) : (
                                this.validator.message(
                                  "note",
                                  this.state.note,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )} */}
                              <CustomToolbar />
                              <ReactQuill
                                ref={this.quillRef}
                                theme="snow"
                                name="note"
                                value={this.state.note ?? ""}
                                onChange={this.handleChange}
                                modules={modules}
                                formats={global.formats}
                                className="custom_editor_style"
                                readOnly={this.state.slug !== undefined ? true : false}
                              />
                              {this.state.showModal && (
                                <LinkModal
                                  onClose={this.handleModalClose}
                                  onSubmit={this.handleModalSubmit}
                                  onRemove={this.handleModalRemove}
                                  url={this.state.url}
                                  text={this.state.text}
                                  isEditing={this.state.isEditing}
                                  showModal={this.state.showModal}
                                />
                              )}
                              {this.state.errors.note ? (
                                <div className="text-danger">
                                  {this.state.errors.note}
                                </div>
                              ) : (
                                this.validator.message(
                                  "note",
                                  this.state.note,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {/* Images Section Start */}
                            <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-20 pd-r-70">
                              <div className="row m-0">
                                <FileWidget
                                  fieldName="file_name"
                                  fieldTitle=""
                                  fieldLabel=""
                                  checkValidation="true"
                                  allowedType="jpeg,jpg,png,JPEG,JPG,PNG,GIF,gif"
                                  maxFileSize={1000}
                                  maxFileSizeType="mb"
                                  maxFileAllowed={5}
                                  uploadOn="s3"
                                  refTableId="3"
                                  displayFileName=""
                                  documentTypeSlug=""
                                  mediaType={1}
                                  sortOrder=""
                                  statusId=""
                                  helpText="To download sample file."
                                  sampleFileUrl="/sample.jpg"
                                  drag="false"
                                  dragText=""
                                  inputClassName="form-control"
                                  labelClassName="form-label"
                                  formID="action_item_form"
                                  uploadType="multiple"
                                  resizeOptions="width:200,height:200,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:300,height:300,maintainAspectRatio:false,resizeDestinationFolder:thumb22"
                                  destinationFolder="audit/action-item"
                                  uploadedFiles={this.state.profile_image2}
                                  deletedFiles={this.state.deletedFileList}
                                  acceptOnly="image/*"
                                  displayPreviewStyle="vertical"
                                  newRelatedFilesDescChange={
                                    this.newRelatedFilesDescChange
                                  }
                                  currentFile={
                                    this.state.actionItemImages.length
                                  }
                                />
                                {/* Image Preview */}

                                {this.state.actionItemImages
                                  ? this.state.actionItemImages.map(
                                      (record, i) => {
                                        return (
                                          <div key={i}>
                                            <div className="img_close">
                                              <a
                                                href={
                                                  record.uri_web
                                                    ? record.uri_web
                                                    : ""
                                                }
                                                rel="noreferrer"
                                                target="_blank"
                                              >
                                                <img
                                                  src={
                                                    record.uri_web
                                                      ? record.uri_web
                                                      : ""
                                                  }
                                                  className="brand_list_image"
                                                  alt={record.slug}
                                                  key={"image" + i}
                                                  onError={({
                                                    currentTarget,
                                                  }) => {
                                                    currentTarget.onerror =
                                                      null; // prevents looping
                                                    currentTarget.src =
                                                      placeholderImage;
                                                  }}
                                                />
                                              </a>
                                              {global.userPermissions.checkPermission(
                                                "action_items-delete"
                                              ) && (
                                                <>
                                                  <button
                                                    type="button"
                                                    key={"button" + i}
                                                    className="btn btn-danger btn_remove"
                                                    onClick={() =>
                                                      this.toggleModal(
                                                        record.slug
                                                      )
                                                    }
                                                  >
                                                    <span className="fa fa-times"></span>
                                                  </button>
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  : ""}
                              </div>
                              {/* Image Preview end*/}
                            </div>
                            {/* Property */}
                            <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left mg-b-5">
                                Property
                              </label>
                              {this.state.slug === undefined ? (
                                <Select
                                  className="multiselect new_drop"
                                  menuPlacement="auto"
                                  value={this.state.selectedPropertyList ?? []}
                                  options={this.state.propertyList ?? []}
                                  onChange={(value) =>
                                    this.handleChangeProperty(value)
                                  }
                                  defaultValue={
                                    this.state.selectedPropertyList ?? []
                                  }
                                  autoComplete="off"
                                  getOptionValue={(option) => `${option.label}`}
                                  styles={customStyles}
                                  isDisabled={this.state.slug ? true : false}
                                  theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary75: "#808080",
                                      primary50: "#808080",
                                      primary25: "#808080",
                                    },
                                  })}
                                />
                              ) : (
                                <input
                                  name="job_title"
                                  className="form-control"
                                  autoComplete="off"
                                  value={this.state.propertyName}
                                  onChange={this.changeHandler}
                                  readOnly
                                />
                              )}
                              {this.state.errors.property_slug ? (
                                <div className="text-danger">
                                  {this.state.errors.property_slug}
                                </div>
                              ) : (
                                this.validator.message(
                                  "property",
                                  this.state.property_slug,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {/* Priority */}
                            <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left mg-b-5">
                                Priority
                              </label>
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                tabIndex="4"
                                value={this.state.selectedPriorityType ?? []}
                                options={this.state.priorityType ?? []}
                                isSearchable={true}
                                onChange={(value) =>
                                  this.handleChangePriority(value)
                                }
                                defaultValue={
                                  this.state.selectedPriorityType ?? []
                                }
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                              {this.state.errors.priority_type ? (
                                <div className="text-danger">
                                  {this.state.errors.priority_type}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Priority",
                                  this.state.priority_type,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {/* Technician */}
                            <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left mg-b-5">
                                Assign To{" "}
                              </label>
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                tabIndex="5"
                                value={this.state.selectedUserList ?? []}
                                options={this.state.userList ?? []}
                                onChange={(value) =>
                                  this.handleChangeUserList(value)
                                }
                                defaultValue={this.state.selectedUserList ?? []}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                              {this.state.errors.user_slug ? (
                                <div className="text-danger">
                                  {this.state.errors.user_slug}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Assignee",
                                  this.state.user_slug,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {/* Due Date */}
                            <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left mg-b-5">
                                Due Date
                              </label>
                              <DatePicker
                                className="form-control max_width_100"
                                tabIndex="6"
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                autoComplete="off"
                                yearDropdownItemNumber={
                                  global.yearDropdownItemNumber
                                }
                                name="due_date"
                                placeholderText="dd-mm-yy"
                                dateFormat="dd MMM yy"
                                selected={this.state.due_date ?? ""}
                                onChange={(data) =>
                                  this.changeHandler({
                                    target: {
                                      type: "date",
                                      name: "due_date",
                                      value: data,
                                    },
                                  })
                                }
                                minDate={Moment().toDate()}
                              />
                              {this.state.errors.due_date ? (
                                <div className="text-danger">
                                  {this.state.errors.due_date}
                                </div>
                              ) : (
                                this.validator.message(
                                  "Due Date",
                                  this.state.due_date,
                                  "required",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            {/* Status */}
                            {this.state.slug !== undefined ? (
                              <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                                <label className="form-label text-left mg-b-5">
                                  Status
                                </label>
                                <Select
                                  className="multiselect new_drop"
                                  menuPlacement="auto"
                                  tabIndex="7"
                                  value={this.state.selectedStatusList ?? []}
                                  options={this.state.statusList ?? []}
                                  isSearchable={true}
                                  onChange={(value) =>
                                    this.handleChangeStatus(value)
                                  }
                                  defaultValue={
                                    this.state.selectedStatusList ?? []
                                  }
                                  autoComplete="off"
                                  getOptionValue={(option) => `${option.label}`}
                                  styles={customStyles}
                                  theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary75: "#808080",
                                      primary50: "#808080",
                                      primary25: "#808080",
                                    },
                                  })}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="row row-xs align-items-center flex-wrap m-0 pd-t-30 pd-b-0 pd-l-80 pd-r-80">
                            <div className="col-md-6 btnNavigate d-flex align-items-center flex-wrap-bothBtn p-0">
                              {this.state.slug !== undefined &&
                                global.userPermissions.checkPermission(
                                  "action_items-delete"
                                ) && (
                                  <>
                                    <button
                                      tabIndex="8"
                                      type="button"
                                      onClick={() => {
                                        this.opendeleteMroModal(
                                          this.state.slug
                                        );
                                      }}
                                      className="detlet-text mg-r-20"
                                    >
                                      Delete Action Item
                                    </button>
                                  </>
                                )}
                            </div>
                            <div className="col-md-6 btnNavigate d-flex align-items-center justify-content-between flex-row-reverse flex-wrap-bothBtn p-0">
                              <div className="d-flex align-items-center">
                                <Link to="/action-item">
                                  <button
                                    tabIndex="9"
                                    className="gf-btn-lg-outline mg-r-7"
                                    type="button"
                                    disabled={
                                      this.state.isSubmit ? true : false
                                    }
                                  >
                                    Cancel
                                  </button>
                                </Link>
                                {this.state.slug == undefined &&
                                  global.userPermissions.checkPermission(
                                    "action_items-add"
                                  ) && (
                                    <button
                                      tabIndex="10"
                                      className="gf-btn-lg-stroke mg-l-2"
                                      type="button"
                                      onClick={this.saveOrUpdateMro}
                                      disabled={
                                        this.state.isSubmit ? true : false
                                      }
                                    >
                                      {this.state.isSubmit
                                        ? global.loader
                                        : "Add"}
                                    </button>
                                  )}
                                {this.state.slug !== undefined &&
                                  global.userPermissions.checkPermission(
                                    "action_items-update"
                                  ) && (
                                    <button
                                      tabIndex="10"
                                      className="gf-btn-lg-stroke mg-l-2"
                                      type="button"
                                      onClick={this.saveOrUpdateMro}
                                      disabled={
                                        this.state.isSubmit ? true : false
                                      }
                                    >
                                      {this.state.isSubmit
                                        ? global.loader
                                        : "Update"}
                                    </button>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            {/* Nav bar end */}
          </div>
        </main>
        {this.state.showModalImage && (
          <DeleteImageModal
            showModalImage={this.state.showModalImage}
            slug={this.state.imageSlug}
            onRemove={() => this.deleteImage(this.state.imageSlug)}
            onClose={this.onCloseImagePop}
          />
        )}
        <Modal show={this.state.deleteMroModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              <h3>Delete Action Item?</h3>
              <p className="pd-l-50 pd-r-50 pd-b-20 model_first_p">
                Do you really want to delete this Action Item? This process
                cannot be undone.
              </p>
              <div className="d-flex cnt_center mt-3 row">
                <button
                  type="button"
                  className=" m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteMroModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className=" m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteActionItem(this.state.slug)}
                  disabled={this.state.isDelete ? true : false}
                >
                  {this.state.isDelete ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ActionItemForm;
